import 'flowbite';
import Rails from '@rails/ujs';
import './active_admin/features/batch_actions.js';
import './active_admin/features/dark_mode_toggle.js';
import './active_admin/features/has_many.js';
import './active_admin/features/filters.js';
import './active_admin/features/main_menu.js';
import './active_admin/features/per_page.js';

Rails.start();
